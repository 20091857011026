export const SlicesName = {
  Accounts: "accounts",
  Auth: "auth",
  Rooms: "rooms",
  Bookings: "bookings",
  Departments: "departments",
};

export const ReducersName = {
  Accounts: "accounts",
  Auth: "auth",
  Rooms: "rooms",
  Bookings: "bookings",
  Departments: "departments",
};
