import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllDepartment,
  uploadImageService,
  createDepartmentsService,
  updateDepartmentsService,
  deleteDepartmentsService,
} from "services/departments";
import { localStorageKeys } from "utils/constants/local_storage";

export const getAllDepartmemts = createAsyncThunk("", async (params) => {
  try {
    const res = await getAllDepartment(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const uploadFileThunk = createAsyncThunk("/files/upload", async (body, thunkAPI) => {
  try {
    let response = await uploadImageService(body);

    return response.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});
export const createDepartments = createAsyncThunk("/create", async (data, thunkAPI) => {
  try {
    const res = await createDepartmentsService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const updateDepartments = createAsyncThunk("/update", async (data) => {
  try {
    const res = await updateDepartmentsService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const deleteDepartments = createAsyncThunk("/delete", async (params) => {
  try {
    const res = await deleteDepartmentsService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
