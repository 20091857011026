export const handleOpenModalAccountFormAction = (state, action) => {
  const { isOpenModal, isCreate, editData = null } = action.payload;
  state.formAction.isOpenModal = isOpenModal;
  state.formAction.isCreate = isCreate;
  state.formAction.editData = editData;
  state.formAction.titleModal = isCreate
    ? "Tạo mới người dùng"
    : "Chỉnh sửa người dùng";
  state.formAction.buttonOkModal = isCreate ? "Tạo mới" : "Chỉnh sửa";
};
