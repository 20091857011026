export const roomsInitialState = {
  rooms_admin: {
    loading: false,
    error: null,
    data: [],
    success: false,
    filter: {}
  },

  rooms_client: {
    loading: false,
    error: null,
    data: null,
    success: false,
    filter: {}
  },

  rooms_create: {
    loading: false,
    error: null,
    data: null,
    success: false
  },

  rooms_update: {
    loading: false,
    error: null,
    data: null,
    success: false
  },

  rooms_delete: {
    loading: false,
    error: null,
    data: null,
    success: false
  },

  bookings_mine: {
    loading: false,
    error: null,
    data: null,
    success: false
  },

  bookings_mine_detail: {
    loading: false,
    error: null,
    data: null,
    success: false
  },

  upload_file: {
    loading: false,
    error: null,
    data: null,
    success: false
  }
};