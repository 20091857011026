export const bookingsInitialState = {
  allBookings: {
    data: [],
    loading: false,
    error: false,
    success: false,
    filter: {}
  },
  bookingsRoom: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },

  booking_create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },

  booking_update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};
