import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";

export const getBookingsService = async (params) => {
  const res = await baseAxios().get(`${endpoints.Booking}`, { params });
  return res;
};

export const cancelBookingService = async (data) => {
  const res = await baseAxios().patch(`${endpoints.Booking}/${data.id}`);
  return res;
};

export const getAllBookingsService = async (params) => {
  const res = await baseAxios().get(`${endpoints.Booking}/full`, { params });
  return res;
};

export const createBookingService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Booking}`, data);
  return res;
};


export const updateBookingService = async (data) => {
  const res = await baseAxios().put(`${endpoints.Booking}/${data.id}`, data);
  return res;
};

export const bookingDetailService = async (data) => {
  const res = await baseAxios().get(`${endpoints.Booking}/${data.id}`);
  return res;
};

export const bookingAdminDetailService = async (data) => {
  const res = await baseAxios().get(`${endpoints.Booking}/full/${data.id}`);
  return res;
};
