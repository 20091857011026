import { createSlice } from "@reduxjs/toolkit";

import { SlicesName } from "utils/app/constants";

import { roomsInitialState } from "./initialState";
import {
  bookingsMineDetailThunk,
  bookingsMineThunk,
  roomsAdminThunk,
  roomsClientThunk,
  roomsCreateThunk,
  roomsDeleteThunk,
  roomsUpdateThunk,
  uploadFileThunk,
} from "./thunks";
import { type } from "@testing-library/user-event/dist/type";

export const roomslice = createSlice({
  name: SlicesName.Rooms,
  initialState: roomsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    [
      {
        thunk: roomsAdminThunk,
        key: "rooms_admin",
      },
      {
        thunk: roomsClientThunk,
        key: "rooms_client",
      },
      {
        thunk: roomsCreateThunk,
        key: "rooms_create",
      },
      {
        thunk: roomsUpdateThunk,
        key: "rooms_update",
      },
      {
        thunk: roomsDeleteThunk,
        key: "rooms_delete",
      },
      {
        thunk: bookingsMineThunk,
        key: "bookings_mine",
      },
      {
        thunk: bookingsMineDetailThunk,
        key: "bookings_mine_detail",
      },
      {
        thunk: uploadFileThunk,
        key: 'upload_file',
      },
    ].forEach((item) => {
      builder.addCase(item.thunk.pending, (state, action) => {
        state[item.key].loading = true;
        state[item.key].error = null;
        state[item.key].success = false;
      });

      builder.addCase(item.thunk.rejected, (state, action) => {
        state[item.key].loading = false;
        state[item.key].error = action.payload || action.error;
        state[item.key].success = false;
      });

      builder.addCase(item.thunk.fulfilled, (state, action) => {
        state[item.key].loading = false;
        state[item.key].error = null;
        state[item.key].success = true;

        if(action.payload) {
          state[item.key].data = action.payload;
          if (state[item.key].filter) {
            state[item.key].filter = action.meta.arg;
          }
        }
        
        if (action.payload?.isError) {
          state[item.key].data = [];
          if (state[item.key].filter) {
            state[item.key].filter = {};
          }
        }
      });
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = roomslice.actions;

export default roomslice.reducer;
