import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "app/store";

import { LocaleProvider } from "./contexts/localeContext"; // Import LocaleProvider

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "toastr/build/toastr.css";

import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <LocaleProvider>
      {" "}
      {/* Use LocaleProvider here */}
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </LocaleProvider>
  </Provider>
);

reportWebVitals();
