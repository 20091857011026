export const authInitialState = {
  login: {
    loading: false,
    error: null,
    data: null,
    success: false
  },

  refresh_token: {
    loading: false,
    error: null,
    data: null,
    success: false
  }
};