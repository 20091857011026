export const accountInitialState = {
  allAccounts: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  accountsCalendar: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  allRoles: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  allDepartments: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  allMine: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  formAction: {
    isOpenModal: false,
    isCreate: false,
    editData: null,
    titleModal: "Tạo mới tài khoản",
    buttonOkModal: "Tạo mới",
  },
};
