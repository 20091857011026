import { createSlice } from "@reduxjs/toolkit";

import { SlicesName } from "utils/app/constants";

import { authInitialState } from "./initialState";
import { loginThunk, refreshTokenThunk } from "./thunks";
import { localStorageKeys } from "utils/constants/local_storage";

export const authlice = createSlice({
  name: SlicesName.Auth,
  initialState: authInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state, action) => {
        state.login.loading = true
        state.login.error = null
        state.login.success = false
        state.login.data = null
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.login.loading = false
        state.login.error = action.payload || action.error
        state.login.success = false
        state.login.data = null
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.login.loading = false
        state.login.error = null
        state.login.success = true
        if(action.payload) {
          state.login.data = action.payload
          localStorage.setItem(localStorageKeys.TOKEN, JSON.stringify(action.payload?.jwt || "{}"))
          localStorage.setItem(localStorageKeys.MY_INFO, JSON.stringify(action.payload?.userInfo || "{}"))
          window.location.reload()
        }
      })

    builder
      .addCase(refreshTokenThunk.pending, (state, action) => {
        state.refresh_token.loading = true
        state.refresh_token.error = null
        state.refresh_token.success = false
        state.refresh_token.data = null
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.refresh_token.loading = false
        state.refresh_token.error = action.payload || action.error
        state.refresh_token.success = false
        state.refresh_token.data = null
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.refresh_token.loading = false
        state.refresh_token.error = null
        state.refresh_token.success = true
        state.refresh_token.data = action.payload
        localStorage.setItem(localStorageKeys.TOKEN, JSON.stringify(action.payload || {}))
      })
  },
});

// Action creators are generated for each case reducer function
export const { } = authlice.actions;

export default authlice.reducer;
