import { createSlice } from "@reduxjs/toolkit";

import { SlicesName } from "utils/app/constants";

import { bookingsInitialState } from "./initialState";
import { getBookings, getAllBookings, createBooking, updateBooking } from "./thunks";

export const bookingslice = createSlice({
  name: SlicesName.Rooms,
  initialState: bookingsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET BOOKINGS
    builder.addCase(getBookings.fulfilled, (state, action) => {
      state.bookingsRoom.loading = false;
      state.bookingsRoom.error = null;
      if (action.payload?.code === 200) {
        state.bookingsRoom.data = action.payload?.data;
        state.bookingsRoom.success = true;
      }
    });
    builder.addCase(getBookings.pending, (state, action) => {
      state.bookingsRoom.loading = true;
      state.bookingsRoom.error = null;
      state.bookingsRoom.success = false;
    });
    builder.addCase(getBookings.rejected, (state, action) => {
      state.bookingsRoom.loading = false;
      state.bookingsRoom.error = action.payload;
      state.bookingsRoom.success = false;
    });

    // GET ALL BOOKING - ADMIN
    builder.addCase(getAllBookings.fulfilled, (state, action) => {
      state.allBookings.loading = false;
      state.allBookings.error = null;
      if (action.payload?.code === 200) {
        state.allBookings.data = action.payload?.data;
        state.allBookings.success = true;
      }
      state.allBookings.filter = {...action.meta.arg};
      state.allBookings.filter.total = state.allBookings.total
    });
    builder.addCase(getAllBookings.pending, (state, action) => {
      state.allBookings.loading = true;
      state.allBookings.error = null;
      state.allBookings.success = false;
    });
    builder.addCase(getAllBookings.rejected, (state, action) => {
      state.allBookings.loading = false;
      state.allBookings.error = action.payload;
      state.allBookings.success = false;
    });

    // GET ALL BOOKING - ADMIN
    builder.addCase(createBooking.fulfilled, (state, action) => {
      state.booking_create.loading = false;
      state.booking_create.error = null;
      state.booking_create.success = true;
      state.booking_create.data = action.payload;
      
    });
    builder.addCase(createBooking.pending, (state, action) => {
      state.booking_create.loading = true;
      state.booking_create.error = null;
      state.booking_create.success = false;
      state.booking_create.data = null;
    });
    builder.addCase(createBooking.rejected, (state, action) => {
      state.booking_create.loading = false;
      state.booking_create.error = action.payload || action.error;
      state.booking_create.success = false;
      state.booking_create.data = null;
    });

    // GET ALL BOOKING - ADMIN
    builder.addCase(updateBooking.fulfilled, (state, action) => {
      state.booking_update.loading = false;
      state.booking_update.error = null;
      state.booking_update.success = true;
      state.booking_update.data = action.payload;
      
    });
    builder.addCase(updateBooking.pending, (state, action) => {
      state.booking_update.loading = true;
      state.booking_update.error = null;
      state.booking_update.success = false;
      state.booking_update.data = null;
    });
    builder.addCase(updateBooking.rejected, (state, action) => {
      state.booking_update.loading = false;
      state.booking_update.error = action.payload || action.error;
      state.booking_update.success = false;
      state.booking_update.data = null;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = bookingslice.actions;

export default bookingslice.reducer;
