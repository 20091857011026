import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";

export const getAllRolesService = async () => {
  const res = await baseAxios().get(`${endpoints.Role}`);
  return res;
};
export const getAllDepartment = async () => {
  const res = await baseAxios().get(`${endpoints.Departments}`);
  return res;
};
export const getAllMine = async () => {
  const res = await baseAxios().get(`${endpoints.Account}/mine`);
  return res;
};

export const getAllAccountsService = async (params) => {
  const res = await baseAxios().get(`${endpoints.Account}`, { params });
  return res;
};

export const createAccountService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Account}`, data);
  return res;
};

export const updateAccountService = async (data) => {
  const res = await baseAxios().put(`${endpoints.Account}/${data.id}`, data);
  return res;
};

export const deleteAccountService = async (params) => {
  const res = await baseAxios().patch(`${endpoints.Account}/${params.accountId}`);
  return res;
};

export const changePasswordService = async (params) => {
  const res = await baseAxios().patch(`${endpoints.Account}/change-password`, params);
  return res;
};

export const resetPasswordService = async (params) => {
  const res = await baseAxios().patch(`${endpoints.Account}/reset-password/${params.id}`, params);
  return res;
};
