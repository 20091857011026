// LocaleContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { localStorageKeys } from "utils/constants/local_storage";

const LocaleContext = createContext();

export function useLocale() {
  return useContext(LocaleContext);
}

export const LocaleProvider = ({ children }) => {
  const i18next = localStorage.getItem(localStorageKeys.i18) || "vi";
  const [locale, setLocale] = useState(i18next || "vi");
  useEffect(() => {
    const handleStorageChange = () => {
      setLocale(localStorage.getItem(localStorageKeys.i18) || "vi");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const value = { locale, setLocale };

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
