import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import {
  getAllRolesService,
  getAllAccountsService,
  createAccountService,
  updateAccountService,
  deleteAccountService,
  changePasswordService,
  resetPasswordService,
  getAllDepartment,
  getAllMine,
} from "services/account";

export const getAllRoles = createAsyncThunk("/getAllRoles", async () => {
  try {
    const res = await getAllRolesService();
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const getMine = createAsyncThunk("/getAllmine", async () => {
  try {
    const res = await getAllMine();
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const getAllDepartmemts = createAsyncThunk("/getAllDepartments", async (params) => {
  try {
    const res = await getAllDepartment(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const getAllAccounts = createAsyncThunk("/getAll", async (params) => {
  try {
    const res = await getAllAccountsService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getAccountsCalendar = createAsyncThunk("/getAccountsCalendar", async (params) => {
  try {
    const res = await getAllAccountsService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const createAccount = createAsyncThunk("/create", async (data, thunkAPI) => {
  try {
    const res = await createAccountService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const updateAccount = createAsyncThunk("/update", async (data) => {
  try {
    const res = await updateAccountService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const deleteAccount = createAsyncThunk("/delete", async (params) => {
  try {
    const res = await deleteAccountService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const changePassword = createAsyncThunk("/changePassword", async (params) => {
  try {
    const res = await changePasswordService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const resetPassword = createAsyncThunk("/reset-password", async (params) => {
  try {
    const res = await resetPasswordService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
