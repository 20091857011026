import { createAsyncThunk } from "@reduxjs/toolkit"
import { bookingAdminDetailService, bookingDetailService } from "services/bookings"
import { bookingMineDetailService, bookingMineService, roomsAdminService, roomsClientService, roomsCreateService, roomsDeleteService, roomsUpdateService, uploadImageService } from "services/rooms"
import { localStorageKeys } from "utils/constants/local_storage"

export const roomsAdminThunk = createAsyncThunk('/rooms/admin', async (params, thunkAPI) => {
  try {
    const profile = JSON.parse(localStorage.getItem(localStorageKeys.MY_INFO))
    let res
    if (profile.listRole.find(role => role.code === 'ADMIN')) {
      res = await roomsAdminService(params)
    } else {
      res = await roomsClientService(params);
    }

    return res.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const roomsClientThunk = createAsyncThunk('/rooms/client', async (params, thunkAPI) => {
  try {
    const response = await roomsClientService(params)

    return response.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const roomsCreateThunk = createAsyncThunk('/rooms/create', async (params, thunkAPI) => {
  try {
    const roomsCreateResponse = await roomsCreateService(params)
    return roomsCreateResponse
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const roomsUpdateThunk = createAsyncThunk('/rooms/update', async (params, thunkAPI) => {
  try {
    const response = await roomsUpdateService(params)

    return response
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const roomsDeleteThunk = createAsyncThunk('/rooms/delete', async (params, thunkAPI) => {
  try {
    const response = await roomsDeleteService(params)

    return response
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const bookingsMineThunk = createAsyncThunk('/bookings/mine', async (params, thunkAPI) => {
  try {
    const response = await bookingMineService(params)

    return response.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const bookingsMineDetailThunk = createAsyncThunk('/bookings/mine/detail', async (params, thunkAPI) => {
  try {
    const profile = JSON.parse(localStorage.getItem(localStorageKeys.MY_INFO))
    let response

    if (profile.listRole.find(role => role.code === 'ADMIN')) {
      response = await bookingAdminDetailService(params)
    } else {
      response = await bookingDetailService(params)
    }

    return response.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const uploadFileThunk = createAsyncThunk('/files/upload', async (body, thunkAPI) => {
  try {
    let response = await uploadImageService(body)

    return response.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})