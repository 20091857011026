import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";
import { serialize } from "object-to-formdata";

export const getAllDepartment = async (params) => {
  const res = await baseAxios().get(`${endpoints.Departments}`, { params });
  return res;
};
export const uploadImageService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Files}/upload/`, serialize(data), {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
    },
  });
  return res;
};
export const createDepartmentsService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Departments}`, data);
  return res;
};
export const updateDepartmentsService = async (data) => {
  const res = await baseAxios().put(`${endpoints.Departments}/${data.id}`, data);
  return res;
};
export const deleteDepartmentsService = async (params) => {
  const res = await baseAxios().patch(`${endpoints.Departments}/${params.accountId}`);
  return res;
};
