import { message } from "antd";
import { createSlice } from "@reduxjs/toolkit";

import { SlicesName } from "utils/app/constants";
import { localStorageKeys } from "utils/constants/local_storage";

import { handleOpenModalAccountFormAction } from "./actions";
import { getAllRoles, getAllAccounts, getAccountsCalendar, getAllDepartmemts, getMine } from "./thunks";
import { accountInitialState } from "./initialState";

export const counterSlice = createSlice({
  name: SlicesName.Accounts,
  initialState: accountInitialState,
  reducers: {
    handleOpenModalAccountForm: handleOpenModalAccountFormAction,
  },
  extraReducers: (builder) => {
    // GET ALL ACCOUNTS
    builder.addCase(getAllAccounts.fulfilled, (state, action) => {
      state.allAccounts.loading = false;
      state.allAccounts.error = null;
      if (action.payload?.code === 200) {
        state.allAccounts.data = action.payload?.data;
        state.allAccounts.success = true;
      }
      state.allAccounts.filter = {
        ...action.meta.arg,
        total: action.payload?.total,
      };
    });
    builder.addCase(getAllAccounts.pending, (state, action) => {
      state.allAccounts.loading = true;
      state.allAccounts.error = null;
      state.allAccounts.success = false;
    });
    builder.addCase(getAllAccounts.rejected, (state, action) => {
      state.allAccounts.loading = false;
      state.allAccounts.error = action.payload;
      state.allAccounts.success = false;
    });

    // GET ACCOUNTS CALENDAR
    builder.addCase(getAccountsCalendar.fulfilled, (state, action) => {
      state.accountsCalendar.loading = false;
      state.accountsCalendar.error = null;
      if (action.payload?.code === 200) {
        state.accountsCalendar.data = action.payload?.data;
        state.accountsCalendar.success = true;
      }
      state.accountsCalendar.filter = {
        ...action.meta.arg,
        total: action.payload?.total,
      };
    });
    builder.addCase(getAccountsCalendar.pending, (state, action) => {
      state.accountsCalendar.loading = true;
      state.accountsCalendar.error = null;
      state.accountsCalendar.success = false;
    });
    builder.addCase(getAccountsCalendar.rejected, (state, action) => {
      state.accountsCalendar.loading = false;
      state.accountsCalendar.error = action.payload;
      state.accountsCalendar.success = false;
    });

    // GET ALL ROLES
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.allRoles.loading = false;
      state.allRoles.error = null;
      if (action.payload?.code === 200) {
        state.allRoles.data = action.payload?.data;
        state.allRoles.success = true;
      }
    });
    builder.addCase(getAllRoles.pending, (state, action) => {
      state.allRoles.loading = true;
      state.allRoles.error = null;
      state.allRoles.data = [];
      state.allRoles.success = false;
    });
    builder.addCase(getAllRoles.rejected, (state, action) => {
      state.allRoles.loading = false;
      state.allRoles.error = action.payload;
      state.allRoles.success = false;
    });
    // GET ALL Departments
    builder.addCase(getAllDepartmemts.fulfilled, (state, action) => {
      state.allDepartments.loading = false;
      state.allDepartments.error = null;
      if (action.payload?.code === 200) {
        state.allDepartments.data = action.payload?.data;
        state.allDepartments.success = true;
      }
    });
    builder.addCase(getAllDepartmemts.pending, (state, action) => {
      state.allDepartments.loading = true;
      state.allDepartments.error = null;
      state.allDepartments.data = [];
      state.allDepartments.success = false;
    });
    builder.addCase(getAllDepartmemts.rejected, (state, action) => {
      state.allDepartments.loading = false;
      state.allDepartments.error = action.payload;
      state.allDepartments.success = false;
    });
    // GET ALL mine
    builder.addCase(getMine.fulfilled, (state, action) => {
      state.allMine.loading = false;
      state.allMine.error = null;
      if (action.payload?.code === 200) {
        state.allMine.data = action.payload?.data;
        state.allMine.success = true;
      }
    });
    builder.addCase(getMine.pending, (state, action) => {
      state.allMine.loading = true;
      state.allMine.error = null;
      state.allMine.data = [];
      state.allMine.success = false;
    });
    builder.addCase(getMine.rejected, (state, action) => {
      state.allMine.loading = false;
      state.allMine.error = action.payload;
      state.allMine.success = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { handleOpenModalAccountForm } = counterSlice.actions;

export default counterSlice.reducer;
