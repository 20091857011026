import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";
import { serialize } from "object-to-formdata";

export const roomsAdminService = async (data) => {
  const res = await baseAxios().get(`${endpoints.Rooms}/full`, {
    params: data
  });
  return res;
};

export const roomsClientService = async (data) => {
  const res = await baseAxios().get(`${endpoints.Rooms}/`, {
    params: data
  });
  return res;
};

export const roomsCreateService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Rooms}/`, data);
  return res;
};

export const roomsUpdateService = async (data) => {
  const res = await baseAxios().put(`${endpoints.Rooms}/${data.id}`, data);
  return res;
};

export const roomsDeleteService = async (data) => {
  const res = await baseAxios().patch(`${endpoints.Rooms}/${data.id}`);
  return res;
};

export const bookingMineService = async (data) => {
  const res = await baseAxios().get(`${endpoints.Booking}/mine`, { params: data });
  return res;
};

export const bookingMineDetailService = async (data) => {
  const res = await baseAxios().get(`${endpoints.Booking}/mine/${data.id}/`);
  return res;
};

export const uploadImageService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Files}/upload/`, serialize(data), {
    headers: {
      'Content-Type': "multipart/form-data; charset=UTF-8"
    }
  });
  return res;
};