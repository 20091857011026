import { message } from "antd";
import { createSlice } from "@reduxjs/toolkit";

import { SlicesName } from "utils/app/constants";
import { localStorageKeys } from "utils/constants/local_storage";

import { getAllDepartmemts } from "./thunks";
import { departmentsInitialState } from "./initialState";

export const counterSlice = createSlice({
  name: SlicesName.Departments,
  initialState: departmentsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL ACCOUNTS
    builder.addCase(getAllDepartmemts.fulfilled, (state, action) => {
      state.allDepartments.loading = false;
      state.allDepartments.error = null;
      if (action.payload?.code === 200) {
        state.allDepartments.data = action.payload?.data;
        state.allDepartments.success = true;
      }
      state.allDepartments.filter = {
        ...action.meta.arg,
        total: action.payload?.total,
      };
    });
    builder.addCase(getAllDepartmemts.pending, (state, action) => {
      state.allDepartments.loading = true;
      state.allDepartments.error = null;
      state.allDepartments.success = false;
    });
    builder.addCase(getAllDepartmemts.rejected, (state, action) => {
      state.allDepartments.loading = false;
      state.allDepartments.error = action.payload;
      state.allDepartments.success = false;
    });

    // GET ACCOUNTS CALENDAR
  },
});

// Action creators are generated for each case reducer function
export const { handleOpenModalAccountForm } = counterSlice.actions;

export default counterSlice.reducer;
