import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBookingsService,
  cancelBookingService,
  getAllBookingsService,
  createBookingService,
  updateBookingService,
} from "services/bookings";
import { bookingMineService } from "services/rooms";
import { localStorageKeys } from "utils/constants/local_storage";

export const getBookings = createAsyncThunk(
  "/getBookings-client",
  async (params, thunkAPI) => {
    try {
      const profile = JSON.parse(
        localStorage.getItem(localStorageKeys.MY_INFO)
      );
      let res;
      if (profile.listRole.find((role) => role.code === "ADMIN")) {
        res = await getBookingsService(params);
      } else {
        res = await bookingMineService(params);
      }
      return res;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const cancelBooking = createAsyncThunk(
  "/cancelBooking-client",
  async (data, thunkAPI) => {
    try {
      const res = await cancelBookingService(data);
      return res;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

// ADMIN
export const getAllBookings = createAsyncThunk(
  "/getAllBookings-admin",
  async (params, thunkAPI) => {
    try {
      if (!!params.meetingRoom) {
        // Lấy những booking của phòng họp bao gồm label = 1 & 2;
        const profile = JSON.parse(
          localStorage.getItem(localStorageKeys.MY_INFO)
        );
        if (profile.listRole.find((role) => role.code === "ADMIN")) {
          let res = await getAllBookingsService(params);
          let res1 = await getAllBookingsService({ ...params, label: 1 });
          res.data = res.data.concat(res1.data);
          return res;
        } else {
          let res = await getBookingsService(params);
          let res1 = await getBookingsService({ ...params, label: 1 });
          res.data = res.data.concat(res1.data);
          return res;
        }
      } else {
        const profile = JSON.parse(
          localStorage.getItem(localStorageKeys.MY_INFO)
        );
        let res;
        if (profile.listRole.find((role) => role.code === "ADMIN")) {
          res = await getAllBookingsService(params);
        } else {
          res = await getBookingsService(params);
        }

        return res;
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const createBooking = createAsyncThunk(
  "/createBookings",
  async (data, thunkAPI) => {
    try {
      const res = await createBookingService(data);
      return res;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBooking = createAsyncThunk(
  "/updateBooking",
  async (data, thunkAPI) => {
    try {
      const res = await updateBookingService(data);
      return res;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);
