import { configureStore } from "@reduxjs/toolkit";

import { ReducersName } from "utils/app/constants";
import accountsReducer from "app/features/accounts/accountsSlice";
import authReducer from "app/features/auth/authSlice";
import roomsReducer from "app/features/rooms/roomsSlice";
import bookingsReducer from "app/features/bookings/bookingsSlice";
import departmentsReducer from "app/features/departments/departmentsSlice";

const middlewares = [];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: {
    [ReducersName.Accounts]: accountsReducer,
    [ReducersName.Auth]: authReducer,
    [ReducersName.Rooms]: roomsReducer,
    [ReducersName.Bookings]: bookingsReducer,
    [ReducersName.Departments]: departmentsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});
